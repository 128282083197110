import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Last day of Metcon Week`}</em></p>
    <p>{`20:00 AMRAP of:`}</p>
    <p>{`25ft Overhead Barbell Walking Lunge (95/65)`}</p>
    <p>{`15-Power Snatch`}</p>
    <p>{`15-Burpee Over Bar`}</p>
    <p>{`25ft Overhead Barbell Walking Lunge`}</p>
    <p>{`10-Full Snatch`}</p>
    <p>{`10-Burpee Over Bar`}</p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`CrossFit Open WOD 18.1 has been announced and will be Saturday’s
wod.  starts today!  You still have time to register.  Sign up now at
`}<a parentName="em" {...{
            "href": "http://games.crossfit.com%C2%A0and"
          }}>{`http://games.crossfit.com%C2%A0and`}</a>{` be sure to add yourself to team
CrossFit the Ville.  Each Saturday the Open wod will be the class wod
and we will be judging from 9am-12pm.  So come out to participate in the
Open and cheer on your fellow CrossFitters!`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`The next Strength Program will start February 24th.  For more info
email Eric at Fallscitystrength\\@gmail.com.`}</em></strong></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      